import { useEffect } from 'react';
import {
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import Clients from './clients.json';

const getClientData = (location) => {
  const clientId = new URLSearchParams(location.search).get('client_id');
  return Clients[clientId] || Clients.default;
};

const Content = () => {
  const client = getClientData(useLocation());

  useEffect(() => {
    if (client.bgColor) {
      document.body.style.backgroundColor = client.bgColor;
      if (document.getElementsByClassName("cta")[0])
        document.getElementsByClassName("cta")[0].style.color = client.bgColor;
    }
    if (client.color) {
      document.body.style.color = client.color;
      if (document.getElementsByClassName("cta")[0])
        document.getElementsByClassName("cta")[0].style.backgroundColor = client.color;
    }
    if (client.fontFamily)
      document.body.style.fontFamily = client.fontFamily;
  });

  return (
    <>
      <header>
        <img src={`/img/${client.logo}`} rel="prefetch" alt="logo"/>
        <h2>
          Oh nein… etwas ist fehlgeschlagen!
        </h2>
      </header>
      <section dangerouslySetInnerHTML={{__html: client.description}} />
    </>
  );
};

function App() {
  return (
    <Routes>
      <Route path="*" element={<Content />} />
    </Routes>
  );
}

export default App;
